import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import React, { useState, useEffect } from 'react'

export default function VoteSection ({ post, voteOnPost, hasUserDownvoted, hasUserUpvoted, updatePostsVoteState, user }) {
    const [userVote, setUserVote] = useState(0);
    const [voteCount, setVoteCount] = useState(0);

    useEffect(() => {
        if (hasUserUpvoted) {
            setUserVote(1);
        } else if (hasUserDownvoted) {
            setUserVote(-1);
        } else {
            setUserVote(0);
        }
    }, [hasUserUpvoted, hasUserDownvoted]);

    useEffect(() => {
        setVoteCount(post.numUpvotes - post.numDownvotes);
    }, [post]);

    const updateLocalVoteState = (numToIncrement) => {
        let downvoteChange = 0;
        let upvoteChange = 0;
        const oldNumToIncrement = numToIncrement;

        if (!user.isAdmin) {
            if (numToIncrement === 1 && userVote === 1) {
                // undo upvote
                numToIncrement = -1;
                upvoteChange = -1;

                setUserVote(0);
            } else if (numToIncrement === -1 && userVote === -1) {
                // undo downvote
                numToIncrement = 1;
                downvoteChange = -1;

                setUserVote(0);
            } else if (numToIncrement === 1 && userVote === -1) {
                // change downvote to upvote
                numToIncrement = 2;
                downvoteChange = -1;
                upvoteChange = 1;

                setUserVote(1);
            } else if (numToIncrement === -1 && userVote === 1) {
                // change upvote to downvote
                numToIncrement = -2;
                downvoteChange = 1;
                upvoteChange = -1;

                setUserVote(-1);
            } else {
                upvoteChange = numToIncrement === 1 ? 1 : 0;
                downvoteChange = numToIncrement === -1 ? 1 : 0;

                setUserVote(numToIncrement);
            }
        } else {
            setUserVote(numToIncrement);

            upvoteChange = numToIncrement === 1 ? 1 : 0;
            downvoteChange = numToIncrement === -1 ? 1 : 0;
        }

        updatePostsVoteState(post.id, upvoteChange, downvoteChange, oldNumToIncrement);
    }

    return (          
        <div className="flex items-start justify-between mb-2 -ml-2">
            <div className="flex flex-col items-center gap-x-2">
                <button onClick={(e) => {
                        e.preventDefault();
                        voteOnPost(post.id, 1, userVote);
                        updateLocalVoteState(1);
                    }}
                >
                    <ChevronUpIcon className={`h-6 w-6 ${userVote === 1 ? "text-primary" : "text-gray-200"}`} />
                </button>
                <p className="flex text-sm leading-6 text-gray-200">
                    {voteCount}
                </p>
                <button onClick={(e) => {
                        e.preventDefault();
                        voteOnPost(post.id, -1);
                        updateLocalVoteState(-1);
                    }}
                >
                    <ChevronDownIcon className={`h-6 w-6 ${userVote === -1 ? "text-primary" : "text-gray-200"}`} />
                </button>
            </div>
        </div>
    )
}