
import Header from '../Components/Header'
import PostsList from '../Components/PostsList'
import axios from "axios";
import { useState, useEffect } from "react";
import { BASE_URL } from '../Scripts/Constants';
import Loader from '../Components/Loader';
import WritePost from '../Components/WritePost';
import Alert from '../Components/Alert';
import ViewPost from '../Components/ViewPost';
import { getUniqueIdentifier } from '../Scripts/Scripts';

export default function LandingPage() {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [posts, setPosts] = useState([]);
  const [alert, setAlert] = useState(null);
  const [alertType, setAlertType] = useState(null);
  const [commentAlert, setCommentAlert] = useState(null);
  const [commentAlertType, setCommentAlertType] = useState(null);
  const [viewingPost, setViewingPost] = useState(false);
  const [postBeingViewed, setPostBeingViewed] = useState(null);
  const [postsFiltered, setPostsFiltered] = useState([]);
  const [commentsUnfiltered, setCommentsUnfiltered] = useState([]);
  const [commentsBeingViewed, setCommentsBeingViewed] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [sortPostsBy, setSortPostsBy] = useState("Hot");
  const [sortCommentsBy, setSortCommentsBy] = useState("Hot");

  useEffect(() => {
    if (!loading)
      getUser();
  }, []);

  useEffect(() => {
    getPosts();
  }, [sortPostsBy, user]);

  useEffect(() => {
    getCommentsForPost(postBeingViewed?.id);
  }, [sortCommentsBy]);

  useEffect(() => {
    filterPosts(posts, setPostsFiltered);
  }, [posts]);

  useEffect(() => {
    filterPosts(commentsUnfiltered, setCommentsBeingViewed);
  }, [commentsUnfiltered]);

  function updatePostsVoteState (postId, upvoteChange, downvoteChange, numToIncrement) {
    if (!posts || !user) {
      return;
    }

    const updatedPosts = posts.map((post) => {
      if (post._id === postId) {
        post.numUpvotes = (post.numUpvotes || 0) + upvoteChange;
        post.numDownvotes = (post.numDownvotes || 0) + downvoteChange;
        post.hasUserUpvoted = upvoteChange === 1;
        post.hasUserDownvoted = downvoteChange === 1;
      }

      return post;
    });

    const updatedComments = commentsUnfiltered.map((comment) => {
      if (comment._id === postId) {
        comment.numUpvotes = (comment.numUpvotes || 0) + upvoteChange;
        comment.numDownvotes = (comment.numDownvotes || 0) + downvoteChange;
        comment.hasUserUpvoted = upvoteChange === 1;
        comment.hasUserDownvoted = downvoteChange === 1;
      }

      return comment;
    });

    const hasUserDownvoted = (user && user.postsDownvoted && user.postsDownvoted.find((postDownvoted) => postDownvoted.postId === postId)) !== undefined;
    const hasUserUpvoted = (user && user.postsUpvoted && user.postsUpvoted.find((postUpvoted) => postUpvoted.postId === postId)) !== undefined;

    // update user votes
    if (numToIncrement > 0 && hasUserUpvoted) {
      // undo upvote
      user.postsUpvoted = user.postsUpvoted.filter((postUpvoted) => postUpvoted.postId !== postId);
    } else if (numToIncrement < 0 && hasUserDownvoted) {
      // undo downvote
      user.postsDownvoted = user.postsDownvoted.filter((postDownvoted) => postDownvoted.postId !== postId);
    } else if (numToIncrement > 0 && hasUserDownvoted) {
      // change downvote to upvote
      user.postsDownvoted = user.postsDownvoted.filter((postDownvoted) => postDownvoted.postId !== postId);
      user.postsUpvoted.push({ postId: postId, dateCreated: new Date() });
    } else if (numToIncrement < 0 && hasUserUpvoted) {
      // change upvote to downvote
      user.postsUpvoted = user.postsUpvoted.filter((postUpvoted) => postUpvoted.postId !== postId);
      user.postsDownvoted.push({ postId: postId, dateCreated: new Date() });
    } else {
      if (numToIncrement > 0) {
        if (user.postsUpvoted) {
          user.postsUpvoted.push({ postId: postId, dateCreated: new Date() });
        } else {
          user.postsUpvoted = [{ postId: postId, dateCreated: new Date() }];
        }
      } else if (numToIncrement < 0) {
        if (user.postsDownvoted) {
          user.postsDownvoted.push({ postId: postId, dateCreated: new Date() });
        } else {
          user.postsDownvoted = [{ postId: postId, dateCreated: new Date() }];
        }
      }
    }


    if (postBeingViewed && postBeingViewed.id === postId) {
      setPostBeingViewed({
        ...postBeingViewed,
        numUpvotes: postBeingViewed.numUpvotes + upvoteChange,
        numDownvotes: postBeingViewed.numDownvotes + downvoteChange,
        hasUserUpvoted: upvoteChange === 1,
        hasUserDownvoted: downvoteChange === 1,
      });
    }

    setPosts(updatedPosts);
    setCommentsUnfiltered(updatedComments);
  }

  const deletePost = async (postId) => {
    try {
      await axios.post(BASE_URL + "delete_post", {
        postId: postId,
        username: user.username,
        uniqueIdentifier: getUniqueIdentifier(),
      });

      // getPosts();

      // remove post from posts and comments
      const updatedPosts = posts.filter((post) => post._id !== postId);
      const updatedComments = commentsUnfiltered.filter((comment) => comment._id !== postId);

      setPosts(updatedPosts);
      setCommentsUnfiltered(updatedComments);

      setViewingPost(false);
      setPostBeingViewed(null);

      setAlertType("success");
      setAlert("Good riddance!");
    } catch (e) {
      setAlertType("error");
      setAlert(e.data.error);
    }
  }

  const hideOrShowPost = async (postId, showPost=false) => {
    try {
      await axios.post(BASE_URL + "show_or_hide_post", {
        postId: postId,
        username: user.username,
        uniqueIdentifier: getUniqueIdentifier(),
        visible: showPost,
      });

      // getPosts();

      // remove post from posts and comments
      const updatedPosts = posts.map((post) => {
        if (post._id === postId) {
          post.visible = showPost;
        }

        return post;
      });

      const updatedComments = commentsUnfiltered.map((comment) => {
        if (comment._id === postId) {
          comment.visible = showPost;
        }

        return comment;
      });

      setPosts(updatedPosts);
      setCommentsUnfiltered(updatedComments);

      setAlertType("success");

      if (showPost === false) {
        setAlert("Call that post John Cena!");
      } else {
        setAlert("Welcome back, post!");
      }
    } catch (e) {
      setAlertType("error");
      setAlert(e.data.error);
    }
  }

  const voteOnPost = async (postId, numToIncrement) => {
    if (!user) {
      setAlertType("error");
      setAlert("An error occurred when creating your anonymous account. Please try again.");
      return;
    } else if (!postId || !numToIncrement || Math.abs(numToIncrement) !== 1) {
      setAlertType("error");
      setAlert("An error occurred. Please try again.");
      return;
    }

    await axios.post(BASE_URL + "vote", {
      username: user.username,
      postId: postId,
      numToIncrement: numToIncrement,
    });
  }

  const getCommentsForPost = async (postId) => {
    if (!postId) {
      return;
    }

    setLoadingComments(true);
    const res = await axios.post(BASE_URL + "get_comments", {
      postId: postId,
      sortOption: sortCommentsBy.toLowerCase(),
      username: user?.username
    });
    setCommentsUnfiltered(res.data.comments);
    setLoadingComments(false);
  }

  const getPosts = async () => {
    if (!user) {
      return;
    }

    setLoading(true)
    const res = await axios.post(BASE_URL + "get_posts", {
      sortOption: sortPostsBy.toLowerCase(),
      isAdmin: user?.isAdmin,
      username: user?.username
    });
    setPosts(res.data.posts);
    setLoading(false);
  }

  const filterPosts = (postsUnfiltered, setFiltered) => {
    const filtered = [];

    if (!postsUnfiltered) {
      return;
    }

    for (let post of postsUnfiltered) {
      if (post.visible || user?.isAdmin) {
        const numMinutesAgo = Math.floor((new Date() - new Date(post.dateCreated)) / 60000);
        let postedAt = "";

        if (numMinutesAgo < 1) {
          postedAt = "now";
        } else if (numMinutesAgo < 60) {
          postedAt = numMinutesAgo + "m";
        } else if (numMinutesAgo < 1440) {
          const numHoursAgo = Math.floor(numMinutesAgo / 60);
          postedAt = numHoursAgo + "h";
        } else if (numMinutesAgo < 10080) {
          const numDaysAgo = Math.floor(numMinutesAgo / 1440);
          postedAt = numDaysAgo + "d";
        } else if (numMinutesAgo < 40320) {
          const numWeeksAgo = Math.floor(numMinutesAgo / 10080);
          postedAt = numWeeksAgo + "w";
        }

        const hasUserUpvoted = (user && user.postsUpvoted && user.postsUpvoted.find((postUpvoted) => postUpvoted.postId === post._id.toString())) !== undefined;
        const hasUserDownvoted = (user && user.postsDownvoted && user.postsDownvoted.find((postDownvoted) => postDownvoted.postId === post._id.toString())) !== undefined;

        filtered.push({
          id: post._id,
          username: post.username,
          imageUrl: "https://instagram-caption-tool.s3.amazonaws.com/defaultProfilePic.png",
          title: post.title,
          content: post.content,
          date: postedAt,
          dateTime: post.dateCreated,
          numComments: post.numComments,
          type: post.type,
          numUpvotes: post?.numUpvotes || 0,
          numDownvotes: post?.numDownvotes || 0,
          hasUserUpvoted: hasUserUpvoted,
          hasUserDownvoted: hasUserDownvoted,
          visible: post.visible,
        })
      }
    }

    setFiltered(filtered);
  }

  const createPost = async (type, parent, title, content) => {
    if (!user) {
      setAlertType("error");
      setAlert("An error occurred with creating your anonymous account. Please try again.");
      return;
    } else if ((!title && type !== "comment") || !content) {
      setAlertType("error");
      setAlert("Please fill out all fields");
      return;
    }

    if (type === "comment") {
      setLoadingComments(true);
    } else {
      setLoading(true);
    }

    try {
      const res = await axios.post(BASE_URL + "post", {
        username: user.username,
        type: type,
        parent: parent,
        title: title,
        content: content,
      });

      if (type === "comment") {
        if (res.data.status === "success") {
          setCommentAlertType("success");
          setCommentAlert("Comment created successfully!");
        } else {
          setCommentAlertType("error");
          setCommentAlert(res.data.error);
        }

        setLoadingComments(false);
        getCommentsForPost(parent);
      } else {
        if (res.data.status === "success") {
          setAlertType("success");
          setAlert("Post created successfully!");
        } else {
          setAlertType("error");
          setAlert(res.data.error);
        }

        setLoading(false);
        getPosts();
      }
    } catch (e) {
      if (type === "comment") {
        setLoadingComments(false);
        setCommentAlertType("error");
        setCommentAlert(e.data.error);
      } else {
        setLoading(false);
        setAlertType("error");
        setAlert(e.data.error);
      }
    }
  }

  const getUser = async () => {
    setLoading(true);

    if (user) {
      return;
    }

    const uniqueIdentifier = getUniqueIdentifier();
    await axios.post(BASE_URL + "get_user", {
      uniqueIdentifier: uniqueIdentifier,
    }).then((res) => {
      setUser(res.data);
      setLoading(false);
      // getPosts();
    })
  }

  return (
    <>
      <div className="min-h-screen bg-gray-900">
        <Header user={user} imageUrl="https://instagram-caption-tool.s3.amazonaws.com/defaultProfilePic.png" />
        <main className="-mt-32">
          <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            {alert && (
              <div className="my-4 w-full sm:flex sm:justify-center">
                <Alert type={alertType} messageBody="" messageHeader={alert} changeMessage={setAlert}/>
              </div>
            )}
            <WritePost createPost={createPost} user={user} />
            <div className="rounded-lg bg-darkBg px-5 py-2 shadow sm:px-6 my-8">
              {loading ? (
                <div className="flex justify-center items-center h-full my-8">
                  <Loader />
                </div>
              ) : <>
                <ViewPost 
                  open={viewingPost} 
                  setOpen={setViewingPost} 
                  post={postBeingViewed} 
                  comments={commentsBeingViewed} 
                  setPostBeingViewed={setPostBeingViewed} 
                  createPost={createPost} 
                  user={user} 
                  loadingComments={loadingComments} 
                  getCommentsForPost={getCommentsForPost}
                  alert={commentAlert}
                  alertType={commentAlertType}
                  setAlert={setCommentAlert}
                  voteOnPost={voteOnPost}
                  updatePostsVoteState={updatePostsVoteState}
                  sortCommentsBy={sortCommentsBy}
                  setSortCommentsBy={setSortCommentsBy}
                  deletePost={deletePost}
                  hideOrShowPost={hideOrShowPost}
                />
                <PostsList 
                  setPostBeingViewed={setPostBeingViewed} 
                  setViewingPost={setViewingPost} 
                  getCommentsForPost={getCommentsForPost} 
                  user={user}
                  voteOnPost={voteOnPost}
                  postsFiltered={postsFiltered}
                  updatePostsVoteState={updatePostsVoteState}
                  onChangeSort={setSortPostsBy}
                  sortBy={sortPostsBy}
                  postType="post"
                  deletePost={deletePost}
                  hideOrShowPost={hideOrShowPost}
                />
              </>}
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
