import React from 'react'
import SortDropdown from '../Components/SortDropdown';
import Post from './Post';

export default function PostsList({ setPostBeingViewed, setViewingPost, truncate=true, getCommentsForPost, user, voteOnPost, postsFiltered, updatePostsVoteState, onChangeSort, sortBy, postType="post", deletePost, hideOrShowPost }) {
  if (postsFiltered?.length === 0) {
    return (
      <div className="flex justify-center items-center py-4">
        <p className="text-lg font-semibold text-white">No recent {postType}s. You can start the conversation :)</p>
      </div>
    )
  }
  
  return (
    <ul role="list" className="divide-y divide-gray-200">
      <li className="flex w-full justify-between mb-2">
        <h1 className="text-2xl text-white pb-2">
          {postType === "comment" && <span>{postsFiltered.length || 0} comments</span>}
          {postType === "post" && <span>{sortBy} posts</span>}
        </h1>
        <SortDropdown onChangeSort={onChangeSort} sortBy={sortBy} />
      </li>

      {postsFiltered.map((post) => (
        <Post
          post={post} 
          key={post.id} 
          setPostBeingViewed={setPostBeingViewed} 
          setViewingPost={setViewingPost} 
          truncate={truncate} 
          getCommentsForPost={getCommentsForPost} 
          user={user} 
          voteOnPost={voteOnPost} 
          updatePostsVoteState={updatePostsVoteState}
          deletePost={deletePost}
          hideOrShowPost={hideOrShowPost}
        />
      ))}
    </ul>
  )
}  