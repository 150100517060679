import { CheckCircleIcon, XMarkIcon, XCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';

export default function Alert({ type, messageHeader, changeMessage, messageBody = "", setAlertActive }) {
    function onCloseAlertHandler(e) {
        e.preventDefault();

        // 2 methods of closing alert - only 1 required
        if(changeMessage) {
            changeMessage("");
        }
        if(setAlertActive) {
            setAlertActive({});
        }
    }

    if(type === 'success') {
        return (
            <div className="rounded-md bg-green-50 p-4">
            <div className="flex">
                <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                <p className="text-sm font-medium text-green-800">{messageHeader}</p>
                </div>
                <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                    <button
                    type="button"
                    className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                    onClick={onCloseAlertHandler}
                    >
                    <span className="sr-only">Dismiss</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
                </div>
            </div>
            </div>
        );
    } else if(type === 'error') {
        return (
            <div className="rounded-md bg-red-50 p-4 max-w-md">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">{messageHeader}</h3>
                  <div className="mt-2 text-sm text-red-700">
                    <p>
                    {messageBody}
                    </p>
                  </div>
                </div>
                <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                    <button
                    type="button"
                    className="inline-flex rounded-md bg-red-50 p-1.5 text-red-400 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                    onClick={onCloseAlertHandler}
                    >
                    <span className="sr-only">Dismiss</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
                </div>
              </div>
            </div>
        );
    }
    else if(type === 'warning'){
        return (
            <div className="rounded-md bg-yellow-50 p-4">
                <div className="flex">
                <div className="flex-shrink-0">
                    <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">{messageHeader}</h3>
                    <div className="mt-2 text-sm text-yellow-700">
                    <p>
                        {messageBody}
                    </p>
                    </div>
                    <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                    <button
                    type="button"
                    className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                    onClick={onCloseAlertHandler}
                    >
                    <span className="sr-only">Dismiss</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
                </div>
                </div>
                </div>
            </div>
        );
    }
    else{
        console.log("Invalid parameter passed to Alert component");
        return(<></>);
    }
}