import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./Screens/LandingPage";
import Footer from "./Components/Footer";

function App() {
  return (
    <div className="h-full">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </BrowserRouter>
        <Footer />
    </div>
  );
}

export default App;
