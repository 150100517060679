
export default function Footer() {
    return (
        <footer className="bg-gray-800">
        <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
            <div className="mt-8 md:order-1 md:mt-0">
            <p className="text-left text-xs leading-5 text-gray-200">
                &copy; 2024 UR Chan, Inc. 
                <br/>Not associated with the University of Rochester in any way.
            </p>
            </div>
        </div>
        </footer>
    )
}
  