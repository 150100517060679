import { Disclosure, Menu } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import AboutModal from './AboutModal';
import { useState } from 'react';
import TextToParticles from 'text-particles';

const navigation = [
  // { name: 'Home', href: '/', current: true },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header({ user, imageUrl }) {
  const [showAboutModal, setShowAboutModal] = useState(false);

  return (
    <div className="bg-gray-800 pb-32">
      <Disclosure as="nav" className="bg-gray-00">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="border-b border-gray-700">
                <div className="flex h-16 items-center justify-between px-4 sm:px-0">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-12 w-12 bg-white rounded-full p-1"
                        src={"/logo.png"}
                        alt="UR Chan"
                      />
                    </div>
                    <div className="hidden md:block">
                      <div className="flex align-middle justify-center items-center">
                        {/* <p className="text-3xl text-white font-semibold pl-4">UR Chan</p> */}
                        <div className="flex items-center">
                          <TextToParticles
                            text="UR Chan"
                            fontSize={40}
                            numParticles={600}
                            particleSize={1.5}
                            color="white"
                          />
                        </div>
                        <button 
                          className="text-sm text-gray-200 font-semibold pl-6"
                          onClick={e => {
                            e.preventDefault();
                            setShowAboutModal(true);
                          }}
                        >
                          What is this?
                        </button>
                      </div>
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? 'bg-gray-900 text-white'
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                              'rounded-md px-3 py-2 text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">

                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                          <div className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm">
                              <span className="text-gray-200 pr-2">Logged in as <span className="font-bold">{user?.username}</span> (anonymous)</span>
                              <span className="absolute -inset-1.5" />
                              <span className="sr-only">Open user menu</span>
                              {/* <img className="h-8 w-8 rounded-full" src={imageUrl} alt="" /> */}
                          </div>
                      </Menu>
                    </div>
                  </div>
                  <div className="flex align-middle justify-center items-center md:hidden">
                    <p className="text-3xl text-white font-semibold">UR Chan</p>
                    <button 
                      className="text-sm text-gray-200 font-semibold pl-6"
                      onClick={e => {
                        e.preventDefault();
                        setShowAboutModal(true);
                      }}
                    >
                      What is this?
                    </button>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="border-b border-gray-700 md:hidden">
              <div className="space-y-1 px-2 py-3 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block rounded-md px-3 py-2 text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-gray-700 pb-3 pt-4">
                <div className="flex items-center px-5">
                  {/* <div className="flex-shrink-0">
                    <img className="h-10 w-10 rounded-full" src={imageUrl} alt="" />
                  </div> */}
                  <div className="ml-3">
                    <div className="text-base font-medium leading-none text-white">Logged in as <span className="font-bold">{user?.username}</span> (anonymous)</div>
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
        <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            </div>
        </header>

        {showAboutModal && <AboutModal setOpen={setShowAboutModal} open={showAboutModal} />}
    </div>
  );
}