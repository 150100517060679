import { useState } from 'react'
export default function WritePost({ createPost, user, parent }) {
  const [content, setContent] = useState("")

  return (      
    <form className="relative max-w-2xl">
      <div className="overflow-hidden bg-primaryLight rounded-lg border border-gray-300 shadow-sm focus-within:border-primary focus-within:ring-1 focus-within:ring-primary mt-4">
        <label htmlFor="comment" className="sr-only">
          Add a comment
        </label>
        <textarea
          rows={4}
          name="comment"
          id="comment"
          value={content}
          maxLength={1000}
          onChange={e => setContent(e.target.value)}
          className="block w-full resize-none border-0 py-2 text-black placeholder:text-gray-600 focus:ring-0 sm:text-md sm:leading-6 bg-primaryExtraLight"
          placeholder="Add a comment"
        />

        {/* Spacer element to match the height of the toolbar */}
        <div aria-hidden="true">
          <div className="py-2">
            <div className="h-3" />
          </div>
          <div className="h-px" />
          <div className="py-2">
            <div className="py-px">
              <div className="h-3" />
            </div>
          </div>
        </div>
      </div>
      <button 
        className="absolute w-full bottom-0 right-0 sm:w-auto sm:bottom-2 sm:right-3 bg-primary hover:bg-primaryDark text-white font-bold py-2 px-4 rounded"
        onClick={e => {
            e.preventDefault();
            createPost("comment", parent.id, "", content);
            setContent("");
        }}
      >
        <span>Comment anonymously as {user?.username}</span>
      </button>
    </form>
  )
}
