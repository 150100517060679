import NewlineText from './NewlineText'
import Badge from './Badge';
import VoteSection from "./VoteSection"
import { TrashIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'

export default function Post ({ post, setPostBeingViewed, setViewingPost, truncate, getCommentsForPost, user, voteOnPost, updatePostsVoteState, deletePost, hideOrShowPost }) {
    return (
    <li key={post.id} className="flex gap-x-4 py-5">
        <VoteSection post={post} voteOnPost={voteOnPost} hasUserDownvoted={post.hasUserDownvoted} hasUserUpvoted={post.hasUserUpvoted} updatePostsVoteState={updatePostsVoteState} user={user} />
        <div className="flex-auto">
        <div className="flex items-center justify-between gap-x-4 mb-2">
            <div className="flex items-center gap-x-2">
                <img className="h-8 w-8 flex-none rounded-full bg-gray-50" src={post.imageUrl} alt="" />
                <p className="flex text-sm leading-6 text-gray-200">
                Posted by&nbsp;<span className="font-semibold">{post.username}</span>
                {user?.username === post.username && <div className="ml-2"><Badge text="You" color="primary" /></div>}
                </p>
            </div>
            <p className="flex-none text-xs text-gray-200">
            <time dateTime={post.dateTime}>{post.date}</time>
            </p>
        </div>
        <button 
            className="w-full text-left"
            onClick={e => {
                e.preventDefault();
                if (post.type === "comment") {
                return;
                }
                setPostBeingViewed(post);
                getCommentsForPost(post.id);
                setViewingPost(true);
            }}
        >
            <div className="flex flex-col h-full">
            <div className="flex justify-between">
                <div className="w-full">
                {post.type !== "comment" && (
                    <p className="mt-1 line-clamp-2 text-lg font-semibold leading-6 text-gray-100">
                    {post.title}
                    </p>
                )}
                <NewlineText text={post.content} truncate={truncate} />
                </div>
            </div>
                <div className="mt-auto">
                    <div className={`flex ${user?.isAdmin ? "justify-between" : "justify-end"}`}>
                        {user?.isAdmin && (
                            <div className="flex items-center gap-x-4 text-gray-200 mt-2">
                                <button onClick={() => deletePost(post.id)} className="flex items-center gap-x-2 text-gray-200 hover:text-gray-600">
                                    <TrashIcon className="h-6 w-6 text-gray-200 hover:text-gray-400" />
                                </button>
                                <button onClick={() => hideOrShowPost(post.id, !post.visible)} className="flex items-center gap-x-2 text-gray-200 hover:text-gray-600">
                                    {post.visible ? <EyeIcon className="h-6 w-6 text-gray-200 hover:text-gray-400" /> : <EyeSlashIcon className="h-6 w-6 text-gray-200 hover:text-gray-400" />}
                                </button>
                            </div>
                        )}
                                    {post.type !== "comment" && (

                        <span className="text-sm leading-6 text-gray-200 whitespace-nowrap">
                            {post.numComments} comments
                        </span>
                                    )}

                    </div>
                </div>
            </div>
        </button>
        </div>
    </li>
  );
}