import { useState } from 'react'
export default function WritePost({ createPost, user }) {
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")

  return (      
    <form className="relative">
      <div className="overflow-hidden bg-primaryLight rounded-lg border border-gray-300 shadow-sm focus-within:border-primary focus-within:ring-1 focus-within:ring-primary">
        {/* <h1 className="text-black px-3 pt-3 text-4xl font-bold bg-primaryExtraLight">New Post</h1> */}
        <label htmlFor="title" className="sr-only">
          Title
        </label>
        <input
          type="text"
          name="title"
          id="title"
          value={title}
          maxLength={100}
          onChange={e => setTitle(e.target.value)}
          className="block w-full border-0 pt-2.5 text-2xl font-bold placeholder:text-gray-600 focus:ring-0 bg-primaryExtraLight font-semibold"
          placeholder="Your Title Here"
        />
        <label htmlFor="description" className="sr-only">
          Description
        </label>
        <textarea
          rows={4}
          name="description"
          id="description"
          value={description}
          maxLength={1000}
          onChange={e => setDescription(e.target.value)}
          className="block w-full resize-none border-0 py-0 text-black placeholder:text-gray-600 focus:ring-0 sm:text-md sm:leading-6 bg-primaryExtraLight"
          placeholder="Your post content here (max 1000 chars)"
        />

        {/* Spacer element to match the height of the toolbar */}
        <div aria-hidden="true">
          <div className="py-2">
            <div className="h-4" />
          </div>
          <div className="h-px" />
          <div className="py-2">
            <div className="py-px">
              <div className="h-4" />
            </div>
          </div>
        </div>
      </div>
      <button
        className="absolute w-full bottom-0 right-0 sm:w-auto sm:bottom-3 sm:right-3 bg-primary hover:bg-primaryDark text-white font-bold py-2 px-4 rounded"
        onClick={e => {
          e.preventDefault();
          createPost("post", null, title, description);
          setTitle("");
          setDescription("");
        }}
      >
        <span>Post anonymously as {user?.username}</span>
      </button>
    </form>
  )
}
