// gets a unique id for this user from local storage, or creates one if it doesn't exist
export const getUniqueIdentifier = () => {
  let id = localStorage.getItem("uniqueIdentifier");

  if (!id) {
    id = Math.random().toString(36).substring(2, 15);
    localStorage.setItem("uniqueIdentifier", id);
  }

  return id;
}