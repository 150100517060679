import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { Dialog } from '@headlessui/react'
import Loader from './Loader'
import WriteComment from './WriteComment'
import PostsList from './PostsList'
import Alert from './Alert'
import { XMarkIcon } from '@heroicons/react/24/outline'
import VoteSection from './VoteSection'

export default function ViewPost({ open, setOpen, post, comments=[], setPostBeingViewed, createPost, user, loadingComments, alert, alertType, setAlert, voteOnPost, updatePostsVoteState, setSortCommentsBy, sortCommentsBy, deletePost, hideOrShowPost }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:mx-8 md:mx-16 sm:p-6">
                {!post ? (
                  <div className="flex justify-center items-center h-full my-8">
                      <Loader />
                  </div>
                ) : (
                  <div>
                    <div className="flex w-full">
                      <div className="flex gap-x-4 py-5 mr-4">
                        <VoteSection post={post} voteOnPost={voteOnPost} hasUserDownvoted={post.hasUserDownvoted} hasUserUpvoted={post.hasUserUpvoted} updatePostsVoteState={updatePostsVoteState} user={user} />
                      </div>
                      <div className="w-full">
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                          <button
                            type="button"
                            className="rounded-md text-gray-400 hover:text-gray-500"
                            onClick={(e) => {
                              e.preventDefault();
                              setOpen(false);
                              setAlert(null);
                            }}
                          >
                            <span className="sr-only">Close</span>
                            <XMarkIcon className="h-6 w-6 bg-gray-800" aria-hidden="true" />
                          </button>
                        </div>
                          <p className="text-md text-gray-400 pb-2">
                              Posted by {post.username} &#8226; {post.date}
                          </p>
                          <p className="text-3xl text-white pb-2">
                              {post.title}
                          </p>
                          <p className="text-md text-white">
                              {post.content}
                          </p>

                          {alert && (
                            <div className="my-4 w-full sm:flex sm:justify-center">
                              <Alert type={alertType} messageBody="" messageHeader={alert} changeMessage={setAlert}/>
                            </div>
                          )}
                          {user?.isAdmin && (
                            <div className="h-96"></div>
                          )}
                          <WriteComment user={user} createPost={createPost} parent={post} />
                      </div>
                    </div>
                    {loadingComments ? (
                      <div className="flex justify-center items-center h-full my-8">
                        <Loader />
                      </div>
                    ) : (
                      <div className="mt-8">
                        <PostsList 
                          setPostBeingViewed={setPostBeingViewed} 
                          setViewingPost={setOpen} 
                          truncate={false}
                          user={user}
                          voteOnPost={voteOnPost}
                          postsFiltered={comments}
                          updatePostsVoteState={updatePostsVoteState}
                          onChangeSort={setSortCommentsBy}
                          sortBy={sortCommentsBy}
                          postType="comment"
                          deletePost={deletePost}
                          hideOrShowPost={hideOrShowPost}
                        />
                      </div>
                    )}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
