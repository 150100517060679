import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { Dialog } from '@headlessui/react'

export default function WritePost({ open, setOpen }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="w-full">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-200">
                        <div className="flex justify-left items-center mb-2">
                        <img
                          className="h-12 w-12 bg-white rounded-full p-1 mr-4"
                          src={"/logo.png"}
                          alt="UR Chan"
                        />
                        About UR Chan
                      </div>
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-200">
                            UR Chan is a completely anonymous, unmoderated message board for students at the University of Rochester. That being said, please be respectful of others. 
                            <br/><br/>
                            Posts are automatically removed after 7 days.
                            <br/><br/>
                            Not associated with the University of Rochester in any way.
                        </p>
                    </div>
                    <button 
                        className="bottom-3 right-3 bg-primary hover:bg-primaryDark text-white font-bold py-2 px-4 rounded mt-4"
                        onClick={e => {
                            e.preventDefault();
                            setOpen(false);
                        }}
                    >
                        <span>Close</span>
                    </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
